import createPersistedState from "use-persisted-state";

import ConstantData from "data/ConstantData";

import useGlobalUnitsFilterResult from "hooks/useGlobalUnitsFilterResult";


const useGlobalUnitsFilterState = createPersistedState( ConstantData.localStorageKeys.unitsFilter );


const useGlobalUnitsFilter = () => {

	const [globalUnitsFilter, setGlobalUnitsFilter] = useGlobalUnitsFilterState( {
		unitCustomIdsFiltered: [],
	} );

	const globalUnitsFilterResult = useGlobalUnitsFilterResult();

	const filterPreds = {
		unitHomeTypes: ( unitData, filter ) => {
			if ( !unitData.unit_home_type )
				return true;

			if (
				filter['unitHomeTypes'].hasOwnProperty( unitData.unit_home_type.id ) &&
				filter['unitHomeTypes'][unitData.unit_home_type.id].doFilterByOr
			)
				return true;

			return false;
		},
		unitBuildings: ( unitData, filter ) => {
			if ( !unitData.unit_building )
				return false;

			if (
				filter['unitBuildings'].hasOwnProperty( unitData.unit_building.id ) &&
				filter['unitBuildings'][unitData.unit_building.id].doFilterByOr
			)
				return true;

			return false;
		},
		unitFloors: ( unitData, filter ) => {
			// TODO: Figure out if returning true if the unit doesn't have a floor ref is okay
			if ( !unitData.unit_floor )
				return true;

			if (
				filter['unitFloors'].hasOwnProperty( unitData.unit_floor.id ) &&
				filter['unitFloors'][unitData.unit_floor.id].doFilterByOr
			)
				return true;

			return false;
		},

		unitAvailabilityStates: ( unitData, filter ) => {
			if ( !unitData.unit_availability_state )
				return false;

			if (
				filter['unitAvailabilityStates'].hasOwnProperty( unitData.unit_availability_state.id ) &&
				filter['unitAvailabilityStates'][unitData.unit_availability_state.id].doFilterByOr
			)
				return true;

			return false;
		},

		price: ( unitData, filter ) => {
			// console.log( "newFilter", filter );
			// console.log( "guf", globalUnitsFilter );

			if ( !unitData.price )
				return false;

			return unitData.price <= filter['price'].high && unitData.price >= filter['price'].low;
		},
		size: ( unitData, filter ) => {
			// console.log( "newFilter", filter );

			if ( !unitData.interior_sqft )
				return false;

			return unitData.interior_sqft <= filter['size'].high && unitData.interior_sqft >= filter['size'].low;
		},
	};

	// useEffect( () => {
	// 	console.log( "Global Filter Changed" );
	// }, [globalUnitsFilter] );

	return {
		globalUnitsFilter: globalUnitsFilter,
		setSort: ( fieldName, sortDirection ) => {
			setGlobalUnitsFilter( {
				...globalUnitsFilter,
				sortBy: fieldName,
				sortDirection: sortDirection,
			} );
		},
		setFilterByFloor: ( floorId => {
			setGlobalUnitsFilter( {
				...globalUnitsFilter,
				floorId: floorId,
			} );
		} ),
		checkAndInit: ( newFilterType ) => {

			// if ( !newFilterSettings.hasOwnProperty( props.filterType ) )
			// 	newFilterSettings[props.filterType] = {};
		},
		setFilter: ( _filter ) => {
			// console.log( "setting filter" );

			setGlobalUnitsFilter( {
				...globalUnitsFilter,
				filter: _filter
			} );

			// for ( const filterName in _filter ) {
			// 	// TODO: 
			// 	// console.log( "name:", filterName, ConstantData.gqlQueryToSubqueryTypesMap[filterName] );
			// }

			// console.log( 'guf.sf', globalUnitsFilter );
		},
		applyFilter: ( unitsData ) => {
			let haveFoundActiveFilter = false;
			let activeFilterFcns = [];

			for ( const filterName in globalUnitsFilter.filter ) {
				// console.log( 'fn', filterName );

				// Check that there is an active filter for this type
				for ( const filterItemInstance in globalUnitsFilter.filter[filterName] ) {
					if (
						globalUnitsFilter.filter[filterName][filterItemInstance].doFilterByOr ||
						( filterName === 'price' && globalUnitsFilter.filter[filterName] ) ||
						( filterName === 'size' && globalUnitsFilter.filter[filterName] )
					) {
						haveFoundActiveFilter = true;

						if ( activeFilterFcns.indexOf( filterPreds[filterName] ) === -1 )
							activeFilterFcns.push( filterPreds[filterName] );
					}
				}

				// console.log( 'thing', globalUnitsFilter.filterPreds );
				// if ( haveFoundActiveFilter )
				// 	unitsData = unitsData.filter( ( unit ) => globalUnitsFilter.filterPreds[filterName]( unit, globalUnitsFilter.filter ) );
			}

			// console.log( 'fcns:', activeFilterFcns );

			let unitCustomIdsFiltered = [];

			if ( haveFoundActiveFilter )
				unitsData = unitsData.filter( ( unit ) => {
					// let allPredsReturnTrue = true;

					for ( const filterFcnIdx in activeFilterFcns ) {
						if ( !activeFilterFcns[filterFcnIdx]( unit, globalUnitsFilter.filter ) )
							return false;
					}

					unitCustomIdsFiltered.push( unit.custom_id );

					return true;
				} );

			// console.log( 'guf.af.ucif', unitCustomIdsFiltered );
			globalUnitsFilterResult.setUnitCustomIdsFiltered( unitCustomIdsFiltered );

			return unitsData;
		},
		resetFilter: () => {
			setGlobalUnitsFilter( {
				...globalUnitsFilter,
				filter: {},
			})

			// console.log( 'guf.rf', globalUnitsFilter );
		},
		// toggleSavedHome: unitId => {
		// 	let prevUnitIdExistsIdx = globalUnitsFilter.findIndex(elm => elm.id == unitId);
		// 	if (prevUnitIdExistsIdx === -1)
		// 		setGlobalUnitsFilter([...globalUnitsFilter, { id: unitId }]);
		// 	else {
		// 		let newSavedHomes = globalUnitsFilter;
		// 		newSavedHomes.splice(prevUnitIdExistsIdx, 1);
		// 		setGlobalUnitsFilter([...newSavedHomes]);
		// 	}
		// }
	}

}


export default useGlobalUnitsFilter;