import React from "react";

import {
	useLocation,
} from "react-router-dom";

import { createUseStyles } from "react-jss";

import Nav from './Nav';
import HeaderContainer from './HeaderContainer';
import Footer from './Footer';

import SlugData from '../data/SlugData';
import ThemeData from 'data/ThemeData';
import BreadCrumbNav from "./BreadCrumbNav";
import QueryData from "data/QueryData";
import { useQuery } from "@apollo/client";

// import pageBg from "../img/overview_bg_crop4.jpg";



const useStyles = createUseStyles(
	{
		wrapperWrapper: {
			height: '100%',
			height: 'calc(var(--app-height))'
		},
		wrapper: {
			position: 'relative',

			height: 'calc(100vh) !important',
			/* safari */
			height: '100%',

			display: 'flex',
			flexDirection: 'column',

			// color: '#EEEDE6',
			fontFamily: ThemeData.fonts.titleSub,
		},
		backButtonWrapper: {
			display: 'flex',
			alignItems: 'center',

		},
		backButton: {
			flex: '0 0 auto',
			cursor: 'pointer',
			paddingLeft: 40,

			color: ThemeData.colours.primaryFont,
			fontSize: 24,
		},
		bgBlack: {
			backgroundColor: ThemeData.colours.primaryBgTransparent,
		},
		bgWrapper: {
			height: '100%',
			backgroundImage: props => `url(${props.backgroundImage})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: 'cover',
		},
		bgDarker: {
			// opacity: .25,
		},
		bgNone: {
			backgroundImage: 'none',
		},
		contentPageHeaderWrapper: {
			backgroundColor: ThemeData.colours.neuVibrantDarkBlue,
		},
		equalSized: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		middle: {
			flex: '1 1 auto',
			backgroundColor: props => props.homepage ? 'none' : 'rgba(0,0,0,0.75)',
		},
	},
	{
		name: 'Layout',
	}
);



function RenderLayout( data, props, children ) {
	const location = useLocation();
	const currentLocationPathname = SlugData.determineCurrentLocation( location.pathname );
	const homepage = currentLocationPathname === SlugData.HOME_SLUG_NAME;
	const backgroundImage = data && data.global.defaultSeo.shareImage.url
	const classes = useStyles({homepage, backgroundImage});

	return (
		<div className={classes.wrapperWrapper}>
			<div className={`${classes.equalSized} ${classes.bgBlack}`} />
			<div className={`${classes.equalSized} ${classes.bgWrapper} ${currentLocationPathname !== SlugData.HOME_SLUG_NAME ? classes.bgNone : ""}`} />
			<div className={`${classes.equalSized} ${classes.bgWrapper} ${currentLocationPathname !== SlugData.HOME_SLUG_NAME ? classes.bgDarker : ""}`} />
			<div className={`${classes.wrapper}`}>
				{location.pathname.includes('/proximation') ? <BreadCrumbNav
					isVirtualTourOpen={props.isVirtualTourOpen}
					setIsVirtualTourOpenToClosed={props.setIsVirtualTourOpenToClosed}
					isViewStudySlideUpOpen={props.isViewStudySlideUpOpen}
					setIsViewStudySlideUpOpenToClosed={props.setIsViewStudySlideUpOpenToClosed}
					selectedUnitData={props.selectedUnitData}
					unitDetailOpen={props.isUnitDetailOpen}
					setUnitDetailOpen={props.setUnitDetailOpen}
					toggleIsSavedHomesSlideoutOpen={() => props.toggleIsSavedHomesSlideoutOpen()} 
					toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}	
				/> : 
				<HeaderContainer
					toggleIsSavedHomesSlideoutOpen={() => props.toggleIsSavedHomesSlideoutOpen()}
					toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}
				/> }
				<div className={classes.middle}>
					{children}
				</div>
				<Footer hidden={props.footerHidden}>
					<Nav navLocation="bottomNav" />
				</Footer>
			</div>
		</div>
	);
}



function Layout( { children, ...props } ) {
	const { data } = useQuery(QueryData.global);
	return RenderLayout( data, props, children );
}



export default Layout;
