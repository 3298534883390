import React, {
	useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import FloorplansGeneric from "./FloorplansGeneric";
import ViewStudy from "./ViewStudy";



const useStyles = createUseStyles(
	{
		wrapper: {
			width: '100%',
			height: '100%',
		},
	},
	{
		name: "Floorplans",
	}
)



const Floorplans = ( { data, className, ...props } ) => {

	const classes = useStyles();

	const [selectedUnitData, setSelectedUnitData] = useState( null );
	return (
		<div className={`${classes.wrapper} ${className}`}>
			<FloorplansGeneric
				location={props.location}
				isVirtualTourOpen={props.isVirtualTourOpen}
				toggleIsVirtualTourOpen={props.toggleIsVirtualTourOpen}
				overrideBackgroundColor={props.overrideBackgroundColor}
				unitCardActionButtonProps={props.unitCardActionButtonProps}
				isViewStudySlideUpOpen={props.isViewStudySlideUpOpen}
				toggleIsViewStudySlideUpOpenFcn={props.toggleIsViewStudySlideUpOpen}
				viewUrl={props.viewUrl}
				selectedUnitData={selectedUnitData}
				setSelectedUnitData={setSelectedUnitData}
			/>
		</div>
	);
}



Floorplans.defaultProps = {
	overrideBackgroundColor: null,
}



export default Floorplans;