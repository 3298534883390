import React, { useState } from "react";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	AnimatePresence,
	motion,
} from "framer-motion";

import ThemeData from "../data/ThemeData";
import QueryData from "../data/QueryData";
import NetData from "../data/NetData";
import ConstantData from "data/ConstantData";
// import SiteConfigData from "data/SiteConfigData";

import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import ButtonCircle from "./ButtonCircle";
import UnitInfoIconList from "./UnitInfoIconList";
import VirtualTour from "./VirtualTour";


import useSavedHomes from "hooks/useSavedHomes";
import useOpenClosePane from "hooks/useOpenClosePane";
import useSiteConfiguration from "hooks/useSiteConfiguration";


import imgFunctions from "util/imgFunctions";

import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";

import { ReactComponent as DownloadIcon } from "../img/icon_download.svg";
import SlideUpGenericHolder from "./SlideUpGenericHolder";
import ContentPage from "./ContentPage";
import { UnitDetailCarousel } from "./UnitDetailCarousel";
import { BookMarkButton } from "./BookMarkButton";

import crossIcon from '../img/cross_icon.svg';

const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'absolute',
			top: 100,
			left: 0,
			width: '-webkit-fill-available',
			height: 'calc(100vh - 100px)',
			color: ThemeData.colours.secondaryFont,
			backgroundColor: ThemeData.colours.primaryBg,
			fontFamily: ThemeData.fonts.title,
			'& ::-webkit-scrollbar': {
				display: 'none'
			},
			overflow: 'hidden'
		},
		buttonGroup: {
			'@media (max-width: 1200px)':{
				margin: '15px 20px 0 20px',
			},
			margin: '25px 20px 0 20px',
			left: '0',
			right: '0',
			top: '-20px'
		}, 
		header: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: 100,
			backgroundColor: ThemeData.colours.primaryHeaderBg,
		},
		backButton: {
			width: 100,
			height: 100,
			cursor: 'pointer',
			color: '#FFF',
			background: 'no-repeat center',
			backgroundImage: `url( ${navArrowLeftDark} )`,
		},
		twoColumn: {
			display: 'flex',
			justifyContent: 'center',

			height: 'calc(100vh - 100px)',
		},
		column: {
			flex: '1 0 auto',

			boxSizing: 'border-box',
			width: 'calc( 50% - 200px )',

			paddingTop: '40px',
		},
		leftColumn: {
			overflowY: 'scroll',
			backgroundColor: ThemeData.colours.white,
		},
		rightColumn: {
			overflowY: 'scroll',
			paddingLeft: 50,
			paddingRight: 50,
			backgroundColor: ThemeData.colours.black,
			backgroundImage: 'url(https://storage.googleapis.com/pxa-arcane-prod/arcane_left_panel_1e8af9f8c7/arcane_left_panel_1e8af9f8c7.png)',
			backgroundSize: 'cover'
		},
		floorPlan: {

		},
		floorPlanImg: {
			maxHeight: 750,
			width: '100%',
			height: '100%',
			objectFit: 'contain',
			marginBottom: 5,
			cursor: 'pointer'
		},
		floorPlanImgExpanded: {
			height: 'calc(100vh - 100px)',
			width: '100%',
			objectFit: 'contain',
		},
		floorPlanButtons: {
			// position: 'relative',
			margin: '50px auto 10px auto',
			maxWidth: 350,
		},
		keyplan: {
			paddingTop: 25,
			display: 'flex',
			justifyContent: 'center',
		},
		floorplanModalContainer: {
			display: 'flex',
			position: 'fixed',
			top: 0,
			left: 0,
			backgroundColor: 'rgba(0,0,0,0.8)',
			width: '100%',
			height: '100vh',
			overflow: 'hidden',
			zIndex: 99999,
			justifyContent: 'center',
			alignItems: 'center'
		},
		floorplanModalContent: {
			display: 'block',
			justifyContent: 'center',
			alignItems: 'center',
		},
		floorplanModalHeader: {
			display: 'flex',
			width: '100%',
			height: '60px',
			borderBottom: '1px solid #EEEDE6',
			alignItems: 'center',
			backgroundColor: ThemeData.colours.white,
			'& > img': {
				alignSelf: 'flex-end',
				height: 14,
				width: 14,
				marginRight: 28,
				margin: 'auto 0',
				cursor: 'pointer',
			},
			'& > div': {
				alignSelf: 'center',
				flexGrow: '3',
				textAlign: 'center',
				paddingLeft: 42,
                fontSize: 18,
                fontFamily: ThemeData.fonts.title,
			    color: ThemeData.colours.primaryBg,
			}
		},
		nArrow: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',

			marginRight: 50,
		},
		nArrowArrow: {
			flex: '0 0 auto',

			width: 20,
			height: 33,
		},
		nArrowN: {
			flex: '0 0 auto',

			color: ThemeData.colours.primaryBg,
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 23,
		},
		floorplanName: {
			color: ThemeData.colours.secondaryTitleFont,

			textAlign: 'left',
			fontFamily: ThemeData.fonts.title,
			fontSize: 40,
		},
		hr: {
			// width: '100%',
			'@media (max-width: 1200px)':{
				margin: '15px 20px 0 20px',
			},
			margin: '25px 20px 0 20px',
			borderTop: `1px ${ThemeData.colours.lighterBorderDarkOnLight} solid`,
		},
		actionButtons: {
			width: '100%',
			//width: 150,
			marginTop: 35,
			marginInline: 'auto',
		},
		detailListItems: {
			margin: 0,
			marginTop: 50,
			padding: 0,

			display: 'flex',
			justifyContent: 'left',

			listStyleType: 'none',

			textAlign: 'left',
			letterSpacing: '.05em',
		},
		detailList: {
		},
		detailListItem: {
			width: 150,

			flex: '0 0 auto',
		},
		detailListItemIcon: {

		},
		detailListItemInline: {
			display: 'inline-block',
			marginRight: 10,
		},
		detailListItemTitle: {
			color: ThemeData.colours.white,

			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		detailListItemData: {
			marginTop: 10,

			letterSpacing: '.05em',
			color: ThemeData.colours.primaryBg,
			fontSize: 16,
		},
		detailListItemDataModifier: {
			fontFamily: ThemeData.fonts.copy,
		},
		unitDescription: {
			margin: '20px 10px 0 10px',

			color: ThemeData.colours.white,

			textAlign: 'left',
			lineHeight: '1.9em',
			fontFamily: ThemeData.fonts.copy,
			fontSize: 14,
			letterSpacing: '.025em',
		},
		furtherUnitDetailsButtons: {
			marginTop: 40,
		},
		keyplanImg: {
			flex: '0 0 auto',
			// minWidth: 160,
			maxWidth: '25%',
			maxHeight: '100%',
			objectFit: 'contain',
			marginBottom: 5
		},
		bookMarkWrap: {
			paddingRight: '100px'
		},
		disclaimer: {
			textAlign: 'center',
			fontSize: 8,
			margin: '0 40px',
			fontStyle: 'italic',
			fontWeight: 100,
		}
	},
	{
		name: "UnitDetail",
	}
)


function RenderUnitDetail( unitId, unit, units, classes, error, isOpen, toggleUnitDetailFcn, hooks, setUnitFromCarousel, setGlobalUnit, bookAppointmentURL, isSavedHomesCompareListOpen ) {
	
	const wrapperAnimVariants = {
		open: {
			y: 0,
			zIndex: isSavedHomesCompareListOpen ? 9999 : 999,
			display: 'block',
		},
		closed: {
			y: "100vh",
			display: 'block',
			transitionEnd: {
				zIndex: -2,
				display: 'none'
			},
		},
	}
	const { disclaimerData } = ConstantData;
	const apiUri = NetData.apiUri;

	const [isFloorplanModalOpen, setIsFloorplanModalOpen] = useState(false);

	return (
		<>
			<motion.div
				id="unit-details"
				className={classes.wrapper}
				initial={false}
				animate={isOpen ? "open" : "closed"}
				variants={wrapperAnimVariants}
				transition={{
					type: 'linear',
					duration: .5,
				}}
			>
				<div className={classes.twoColumn}>
					<div className={`${classes.column} ${classes.leftColumn}`}>
						<div className={classes.floorPlan}>
							{unit && unit.unit_plan_type && unit.unit_plan_type.floorplan_image &&
							<AnimatePresence>
								<motion.div
									key="fadein-image"
									initial={{opacity: 0}}
									animate={{opacity: 1}}
									exit={{opacity: 1}}
									>
									<img
										className={classes.floorPlanImg}
										src={imgFunctions.GetImgBySizeUri( unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
										alt={unit.unit_plan_type.floorplan_image.alternativeText}
										width={imgFunctions.GetImgBySizeWidth(unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
										height={imgFunctions.GetImgBySizeHeight(unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
										{...imgFunctions.GetAllImgElmProps( unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.xlarge )}
										onClick={() => setIsFloorplanModalOpen(true)}
										/>
										{/* {TODO: replace with strapi location: currently using hardcoded json file} */}
										{/* <p>{hooks.disclaimerData.data.contactInfo.disclaimer_2}</p> */}
										<p className={classes.disclaimer}>{disclaimerData.disclaimer_2}</p>
										<p className={classes.disclaimer}>{disclaimerData.disclaimer_3}</p>
								</motion.div>
							</AnimatePresence>
							}
							{( !unit || !unit.unit_plan_type || !unit.unit_plan_type.floorplan_image ) &&
								<>
									<img
										className={classes.floorPlanImg}
										src={`${apiUri}/uploads/floorplan_placeholder_large_1680295ea2.png`}
										alt={'floorplan placeholder large'} />
										{ /* {TODO: replace with strapi location: currently using hardcoded json file} */}
										{/* <p>{hooks.disclaimerData.data.contactInfo.disclaimer_2}</p> */}
										<p className={classes.disclaimer}>{disclaimerData.disclaimer_2}</p>
										<p className={classes.disclaimer}>{disclaimerData.disclaimer_3}</p>
								</>
							}
						</div>
						<div className={classes.floorPlanButtons}>
							<ButtonGroup>
								{unit && unit.view_study_iframe_url &&
									<ButtonOutline
										id="enjoy-the-view-unit-details"
										label="Enjoy the View"
										padding={20}
										textColour={ThemeData.colours.primaryAccent}
										hoverTextColour={ThemeData.colours.white}
										borderColour={ThemeData.colours.lighterBorderDarkOnLight}
										to="."
										firstInColl

										onClick={() => hooks.toggleIsViewStudySlideUpOpen()}
									/>
								}
								<ButtonOutline
									label="Enter Showhome"
									padding={20}
									textColour={ThemeData.colours.primaryAccent}
									hoverTextColour={ThemeData.colours.white}
									borderColour={ThemeData.colours.lighterBorderDarkOnLight}
									to="."
									firstInColl

									onClick={() => hooks.toggleIsVirtualTourOpen()}
								/>
							</ButtonGroup>
						</div>
					</div>
					<div className={`${classes.column} ${classes.rightColumn}`}>
						{isOpen && <UnitDetailCarousel unit={unit} units={units} setSelectedUnitId={setUnitFromCarousel} setGlobalUnit={setGlobalUnit} />}
						<div className={classes.actionButtons}>
							
							<div className={classes.hr} />
							<ButtonGroup className={classes.buttonGroup} justifyContent="center"
							>	{/* TODO: make the Book An Appointment cta available when needed, hide it until then  */}
								{/* <a
									href={bookAppointmentURL}
									target="_blank"
									rel="noreferrer"
								>
									<ButtonOutline
										label="Book An Appointment"
										padding={20}
										textColour={ThemeData.colours.primaryAccent}
										hoverTextColour={ThemeData.colours.white}
										borderColour={ThemeData.colours.lighterBorderDarkOnLight}
										to="."
										firstInColl
									></ButtonOutline>
								</a> */}
								<div className={classes.bookMarkWrap}>
									<ButtonCircle
										textColour={ThemeData.colours.primaryAccent}
										borderColor={ThemeData.colours.primaryAccent}
									>
										<BookMarkButton hooks={hooks} dataUnit={unit} height={30} width={20} />
									</ButtonCircle>
								</div>
								<a
									href={unit && unit.floorplan_pdf && unit.floorplan_pdf.url}
									download
									target="_blank"
									rel="noopener noreferrer"
								>
									<ButtonCircle
										alt="Download PDF"
										textColour={ThemeData.colours.primaryAccent}
										backgroundColour={ThemeData.colours.transparent}
										hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
										hoverBackgroundColour={ThemeData.colours.primaryFontHighestConstrast}
										borderColor={ThemeData.colours.primaryAccent}
									>
										<DownloadIcon />
									</ButtonCircle>
								</a>
							</ButtonGroup>
							<div className={classes.hr} />
						</div>
						
						<UnitInfoIconList
							dataUnit={unit && unit}
						/>
						<div className={classes.unitDescription}>
							{unit && unit.unit_plan_type.description}
						</div>
						<div className={classes.furtherUnitDetailsButtons}>
							<ButtonGroup justifyContent="center">
								<ButtonOutline
									label="Features"
									padding={20}
									textColour={ThemeData.colours.primaryAccent}
									hoverTextColour={ThemeData.colours.white}
									borderColour={ThemeData.colours.lighterBorderDarkOnLight}
									to="."
									firstInColl

									onClick={() => hooks.toggleIsFeaturesSlideUpOpen()}
								/>
							</ButtonGroup>
						</div>
						<div className={classes.keyplan}>
							<img
								className={classes.keyplanImg}
								src={NetData.imgUri( unit && unit.unit_plan_type.keyplan_image && unit.unit_plan_type.keyplan_image.url )}
								alt={unit && unit.unit_plan_type.keyplan_image && unit.unit_plan_type.keyplan_image.alternativeText} />
						</div>
					</div>
				</div>
			</motion.div>
			{isFloorplanModalOpen && unit && unit.unit_plan_type && unit.unit_plan_type.floorplan_image &&
				<div className={classes.floorplanModalContainer}>
					<div className={classes.floorplanModalContent}>
						<div className={classes.floorplanModalHeader}>
							<div>{unit.unit_plan_type.name}</div>
							<img onClick={() => setIsFloorplanModalOpen(false)} src={crossIcon} alt="close" />
						</div>
						<img
							className={classes.floorPlanImgExpanded}
							src={imgFunctions.GetImgBySizeUri( unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
							alt={unit.unit_plan_type.floorplan_image.alternativeText}
							width={imgFunctions.GetImgBySizeWidth(unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
							height={imgFunctions.GetImgBySizeHeight(unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
							{...imgFunctions.GetAllImgElmProps( unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.xlarge )}
						/>
						<div>
							<p className={classes.disclaimer}>{disclaimerData.disclaimer_2}</p>
							<p className={classes.disclaimer}>{disclaimerData.disclaimer_3}</p>
						</div>
					</div>
				</div>	
			}
			<VirtualTour
				isOpen={hooks.isVirtualTourOpen}
				src={hooks.siteConfiguration.data && hooks.siteConfiguration.data.virtual_tour_iframe_url}
				toggleVirtualTourFcn={() => hooks.toggleIsVirtualTourOpen()}
				doUseHeader={true}
			/>
			<SlideUpGenericHolder
				slideUpIsOpen={hooks.isFeaturesSlideUpOpen}
				toggleSlideUpIsOpen={hooks.toggleIsFeaturesSlideUpOpen}
				doUseHeader={true}
			>
				<ContentPage
					overrideUrlContentPageSlug="home-features"
					doRenderHeader={false}
				/>
			</SlideUpGenericHolder>
			<SlideUpGenericHolder
				slideUpIsOpen={hooks.isViewStudySlideUpOpen}
				toggleSlideUpIsOpen={hooks.toggleIsViewStudySlideUpOpen}
				doUseHeader={true}
			>
				{hooks.isViewStudySlideUpOpen && unit &&
					<iframe
						title="View Study"
						width="100%"
						height="100%"
						frameBorder="0"
						src={unit.view_study_iframe_url}
					/>
				}
			</SlideUpGenericHolder>
		</>
	);
}



const UnitDetail = ( { unitId, isOpen, toggleUnitDetailFcn, setSelectedUnitId, isSavedHomesCompareListOpen } ) => {

	const classes = useStyles({isOpen});

	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

	const [unitState, setUnit] = useState(unitId);

	const siteConfiguration = useSiteConfiguration();

	const {
		isVirtualTourOpen,
		toggleIsVirtualTourOpen,
	} = useOpenClosePane( "VirtualTour" )
	const {
		isViewStudySlideUpOpen,
		toggleIsViewStudySlideUpOpen,
	} = useOpenClosePane( "ViewStudySlideUp" )
	const {
		isFeaturesSlideUpOpen,
		toggleIsFeaturesSlideUpOpen,
	} = useOpenClosePane( "FeaturesSlideUp" )

	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes,
		siteConfiguration,
		isVirtualTourOpen,
		toggleIsVirtualTourOpen,
		isViewStudySlideUpOpen,
		toggleIsViewStudySlideUpOpen,
		isFeaturesSlideUpOpen,
		toggleIsFeaturesSlideUpOpen,
	}

	const bookAppointmentURL = "https://calendly.com/oliviabellpm/incrowd-demo?month=2022-06"; // temporary demo link until actual Calendly link is set up

	const { loading, error, data } = useQuery(QueryData.unitProperties);

	if ( loading ) return RenderUnitDetail( unitId, null, null, classes, null, isOpen, toggleUnitDetailFcn, hooks, setUnit, bookAppointmentURL, isSavedHomesCompareListOpen );
	if ( error ) return RenderUnitDetail( unitId, null, null, classes, error, isOpen, toggleUnitDetailFcn, hooks, setUnit, bookAppointmentURL, isSavedHomesCompareListOpen );

	let unit;
	let units;
	if ( data && data.units.length > 0 )
		units = [...data.units]
		let sortedUnits = units.sort((a,b) => a.custom_id - b.custom_id);
		unit = unitState === -1 ? sortedUnits.filter(unit => unit.custom_id === unitId)[0] : sortedUnits.filter(unit => unit.custom_id === unitState)[0]; 
	return RenderUnitDetail( unitId, unit, sortedUnits, classes, null, isOpen, toggleUnitDetailFcn, hooks, setUnit, setSelectedUnitId, bookAppointmentURL, isSavedHomesCompareListOpen );

};



export default UnitDetail;