import React from "react";

import {
	useQuery,
	gql,
} from "@apollo/client";

import {
	createUseStyles
} from "react-jss";

import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";
import { useState } from "react";
import FormModal from "./FormModal.js";


const mainMenuItemsQuery = gql`
	query mainMenuIconItems {
		mainMenuIconItems {
			id
			text
			url
			pdf {
				url
			}
			icon {
				url
				alternativeText
			}
			order
		}
	}
`;


const useStyles = createUseStyles( {
	wrapper: {
		marginTop: 30,

		textAlign: 'left',
		fontFamily: ThemeData.fonts.copy,

		'& ul': {
			padding: 0,

			'& li': {
				margin: '15px 0',

				listStyleType: 'none',

				'& a': {
					display: 'flex',
					alignItems: 'center',
				},

				...ThemeData.styles.linkColourRemoval,
			},
		},
	},
	hr: {
		margin: '20px 0',
	},
	iconItem: {
		fontSize: '15.2px',
		textTransform: 'uppercase'
	},
	iconItemIcon: {
		flex: '0 0 auto',
		display: 'inline-block',

		width: 16,
		height: 16,

		margin: 4,
		marginRight: 20,
	},
	iconItemText: {
		flex: '1 0 auto',

		letterSpacing: '.05em',
	},
} );


function MainMenuIconItems() {

	const classes = useStyles();

	const { loading, error, data } = useQuery( mainMenuItemsQuery );

	const [isSendToEmailOpen, toggleIsSendToEmailOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState();

	if ( loading ) return <p></p>
	if ( error ) return <p>{console.log( error.message )}</p>

	return (
		<>
			{data && data.mainMenuIconItems && data.mainMenuIconItems.length > 0 &&
				<>
					{data.mainMenuIconItems &&
						<>
							<hr className={classes.hr} />
							<div className={classes.wrapper}>
								<ul>
									{data.mainMenuIconItems.map( ( item, index ) => {
										return (
											<li className={classes.iconItem} key={item.id}>
												<a
													href={item.url}
													target="_blank"
													rel="noreferrer"
													download="Thing.pdf">
													<img className={classes.iconItemIcon} src={`${NetData.imgUri( item.icon.url )}`} alt={item.icon.alternativeText} />
													<div className={classes.iconItemText}>{item.text}</div>
												</a>
												{item.pdf && <div style={{ cursor: 'pointer' }}onClick={() => {toggleIsSendToEmailOpen(!isSendToEmailOpen); setSelectedIndex(index)}}>Email</div>}
											</li>
										);
									} )}
								</ul>
							</div>
						</>
					}
					{data.mainMenuIconItems.map((item, index) => <FormModal
						doShow={(selectedIndex === index && isSendToEmailOpen)}
						toggleIsOpen={() => toggleIsSendToEmailOpen()}
						pdfUrl={item.pdf &&`${item.pdf.url}`}
						emailCopy={item.text.replace('Download ', '')}
						sendType="pdfs"
					/>)}
				</>
			}
		</>
	);

}


export default MainMenuIconItems;