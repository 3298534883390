import React, {
	useState,
	useRef,
	useEffect,
} from "react";
import ReactDOM from 'react-dom';

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion,
} from "framer-motion";


import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax


// import neighGeo from "data/neighbourhood";
// import neighGeo from "data/neu-neighbourhood";
// import walkingGeo from "data/walkingIcons";

import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";
import SiteConfigData from "data/SiteConfigData";


// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import mapMarkerDev from "img/map_marker_neu.svg";
// import mapIconWalking from "img/map_icon_walking.svg";


mapboxgl.accessToken = SiteConfigData.MapBox.AccessToken;



const neighGeo = SiteConfigData.MapBox.NeighbourhoodGeoJSON;



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',
			// height: 'calc( 100vh - 100px )',


		},
		map: {
			// backgroundColor: "#000000",

			width: "100%",
			height: 'calc( 100vh - 100px )',

			display: 'none',
			'&.doShow': {
				display: 'block',
			},
		},
		marker: {
			// position: 'relative',

			width: 40,
			height: 40,

			display: 'flex',
			alignItems: 'center',

			color: ThemeData.colours.white,

			fontSize: 18,
			textAlign: 'center',
			letterSpacing: '.025em',

			cursor: 'pointer',

			borderRadius: '50%',

			transition: 'transform .5s ease-out',

			"&:hover $markerTooltip": {
				opacity: 1,
			},

			'&.walkerMarker': {
				width: 70,
				height: 'auto',

				fontFamily: ThemeData.fonts.title,
				fontSize: 8,
				// textAlign: 'center',
				// letterSpacing: '.025em',
				textTransform: 'uppercase',

				borderRadius: 0,
			},
		},
		markerDisplay: {
			flex: '1 1 auto',

			borderRadius: '50%',

			transition: 'transform 1s',

			'&.walkerMarker': {
				lineHeight: '1.4em',
			},
		},
		'.markerTooltip': {
			position: 'absolute',

			opacity: 0,

			maxWidth: 200,

			textAlign: 'center',

			transition: 'all 2s, opacity .5s',

			// color: ThemeData.colours.primaryBg,
			// backgroundColor: 'white',
			backgroundColor: ThemeData.colours.primaryBg,

			border: `1px solid ${ThemeData.colours.eggshellTextBg}`,
			boxShadow: '0px 4px 10px rgb(0 0 0 / 33%)',
			borderRadius: 4,
		},
		markerPopup: {
			fontSize: 40,
			width: 330,
			
			'& .mapboxgl-popup-tip': {
				borderTopColor: ThemeData.colours.secondaryBg,
				marginTop: -1,
			},
			'& .mapboxgl-popup-content': {
				backgroundColor: ThemeData.colours.white,
				color: ThemeData.colours.black,
				padding: 0,
				fontSize: 16,
				'& > img':{
					width: '100%',
					backgroundColor: 'black'
				},
				'& h4': {
					margin: '10px 0 0 0',
					fontSize: 14,
				},
				'& p': {
					margin: '0 0 10px 0',
					fontSize: 15,
				},
			},
			'& .mapboxgl-popup-close-button': {
				color: ThemeData.colours.black,

				fontSize: 24,
			},
		},
		markerHighlight: {
			transform: 'scale( 1.5 )',

			zIndex: 4,
		},
		markerParentHighlight: {
			zIndex: 4,
		},
		dev: {
			width: 60,
			height: 75,

			borderRadius: 0,

			background: "no-repeat center",
			backgroundImage: `url(${mapMarkerDev})`,
		},
		walkingIcon: {
			width: 20,
		},
	},
	{
		name: "Map",
	}
)


// const poiMapboxMarkers = [];

function RenderMap( classes, data, error, doShow, props ) {

	const [prevElms, setPrevElms] = useState( null );
	const [poiMapboxMarkers, setPoiMapboxMarkers] = useState( {} );
	useEffect( () => {
		// console.log( props.highlightCatPoi );
		// console.log( "prev", prevElms );
		let elms;

		if ( props.highlightCatPoi.highCatId !== -1 ) {
			elms = document.querySelectorAll( `[catid='${props.highlightCatPoi.highCatId}']` );
		} else if ( props.highlightCatPoi.highPoiId !== -1 ) {
			elms = document.querySelectorAll( `[poiid='${props.highlightCatPoi.highPoiId}']` );
		}
		// elms = document.querySelectorAll( )

		if ( prevElms ) {
			prevElms.forEach( elm => {
				elm.classList.remove( classes.markerHighlight );
				elm.parentElement.classList.remove( classes.markerParentHighlight );
			} )
			setPrevElms( null );
		}

		if ( elms ) {
			elms.forEach( elm => {
				elm.classList.add( classes.markerHighlight );
				elm.parentElement.classList.add( classes.markerParentHighlight );
			} )
		}

		setPrevElms( elms );
		// console.log( 'endelms', elms, 'endprev', prevElms );
	}, [props.highlightCatPoi, classes.markerHighlight, classes.markerParentHighlight, prevElms] );


	// MapBox Integ
	const mapContainer = useRef( null );
	const map = useRef( null );
	const [
		lng,
		// setLng
	] = useState( SiteConfigData.MapBox.Center.lng );
	const [
		lat,
		// setLat
	] = useState( SiteConfigData.MapBox.Center.lat );
	const [
		zoom,
		// setZoom
	] = useState( SiteConfigData.MapBox.Center.zoom );


	const renderPopup = (newMarker, imgSrc) =>{
		let imageUrl = imgSrc && imgSrc.tileImage && imgSrc.tileImage.url
		return imageUrl ? `<img src="${imageUrl}"/><h4>${newMarker.properties.Category}</h4><br /><p>${newMarker.properties.Name}</p>` : `<h4>${newMarker.properties.Category}</h4><br /><p>${newMarker.properties.Name}</p>`
	}

	useEffect( () => {
		if ( mapContainer && mapContainer.current ) {
			if ( map.current ) return; // initialize map only once
			map.current = new mapboxgl.Map( {
				container: mapContainer.current,
				style: SiteConfigData.MapBox.StyleURL,
				center: [lng, lat],
				zoom: zoom,
				pitch: 60,
				dragRotate: false,
				attributionControl: false,
			} );

			// Add Compass control
			let compassControl = new mapboxgl.NavigationControl( {
				showCompass: true,
				showZoom: false,
			} );
			map.current.addControl( compassControl, "bottom-right" );

			for ( const newMarker of neighGeo.features ) {
				let options = {};

				let marker = <div />;

				if ( newMarker.properties.Number === 0 ) {
					marker = <div className={`${classes.marker} ${classes.dev}`} />;
					options = {
						...options,
						anchor: "bottom",
					}
				} else {

					let markerStyle = {};
					if ( newMarker.properties.IsColorOutline !== "FALSE" ) {
						markerStyle = {
							color: newMarker.properties.Color,
							backgroundColor: "#FFFFFF",
							border: `2px ${newMarker.properties.Color} solid`,
						};
					} else {
						markerStyle = {
							backgroundColor: newMarker.properties.Color,
						}
					}

					marker =
						<div
							className={classes.marker}
							style={markerStyle}
							catid={newMarker.properties.CategoryId}
							poiid={newMarker.properties.Number}
							onMouseEnter={() => props.setHighlightCatPoi( {
								isHighActive: true,
								highCatId: -1,
								highPoiId: newMarker.properties.Number.toString(),
							} )}
							onMouseLeave={() => props.setHighlightCatPoi( {
								isHighActive: false,
								highCatId: -1,
								highPoiId: -1,
							} )}
						>
							<div
								className={classes.markerDisplay}
							>
								{newMarker.properties.Number}
							</div>
						</div>;

				}

				let imgSrc = data && data.neighbourhoodLocations.filter(location => location.name === newMarker.properties.Name)[0]
				const popup = new mapboxgl.Popup( {
					offset: 25,
					// className: 'markerPopup',
					className: classes.markerPopup,
					closeButton: false,
				} )
					.setLngLat( newMarker.geometry.coordinates )
					.setHTML( renderPopup(newMarker, imgSrc) );

				const markerDiv = document.createElement( 'div' );
				ReactDOM.render( marker, markerDiv );

				const newMarkerObj = new mapboxgl.Marker( markerDiv, options )
					.setLngLat( newMarker.geometry.coordinates )
					.setPopup( popup )
					.addTo( map.current );

				// Add markers to the map.
				// new mapboxgl.Marker(el, options)
				setPoiMapboxMarkers( prevPoiMapboxMarkers => {
					return {
						...prevPoiMapboxMarkers,
						[newMarker.properties.Number]: newMarkerObj,
					};
				} )
				// console.log( el );
			}

			// Add walking distance icon markers
			// for ( const newMarker of walkingGeo.features ) {
			// 	let options = {
			// 		anchor: 'bottom',
			// 	};

			// 	let marker = <div />;

			// 	let markerStyle = {
			// 		color: newMarker.properties.Color,
			// 	}

			// 	marker =
			// 		<div
			// 			className={`${classes.marker} walkerMarker`}
			// 			style={markerStyle}
			// 			// whileHover={{ scale: 1.2 }}
			// 			onClick={() => {
			// 				map.current.flyTo( {
			// 					center: newMarker.geometry.coordinates,
			// 					speed: .5,
			// 				} );
			// 			}}
			// 		>
			// 			<div
			// 				className={`${classes.markerDisplay} walkerMarker`}
			// 			>
			// 				<img className={classes.walkingIcon} src={mapIconWalking} alt="Walking Distance Icon" /><br />
			// 				{newMarker.properties.Name}
			// 			</div>
			// 		</div>;

			// 	const markerDiv = document.createElement( 'div' );
			// 	ReactDOM.render( marker, markerDiv );

			// 	new mapboxgl.Marker( markerDiv, options )
			// 		.setLngLat( newMarker.geometry.coordinates )
			// 		.addTo( map.current );
			// }

			// console.log( 'poiMarkers:', poiMapboxMarkers );
		}
	}, [lng, lat, zoom, classes.markerPopup, classes.marker, classes.dev, classes.markerDisplay, props.setHighlightCatPoi] );


	useEffect( () => {
		if ( props.procFlyTo.doProcFlyTo ) {
			if ( props.procFlyTo.poiid !== -1 ) {
				map.current.flyTo( {
					center: props.procFlyTo.coordinates,
					speed: 1,
				} );

				for ( const key in poiMapboxMarkers ) {
					if ( poiMapboxMarkers[key].getPopup().isOpen() )
						poiMapboxMarkers[key].togglePopup();
				};

				poiMapboxMarkers[props.procFlyTo.poiid].togglePopup();
			}
		}
	}, [props.procFlyTo] );


	return (
		<div ref={mapContainer} className={`${classes.map} ${doShow ? "doShow" : ""}`} />
	);
}

const Map = ( { doShow, ...props } ) => {

	const classes = useStyles();


	const { loading, error, data } = useQuery( QueryData.neighbourhoodLocations );

	if ( loading ) return RenderMap( classes, null, null, doShow, props );
	if ( error ) return RenderMap( classes, null, error, doShow, props );
	return RenderMap( classes, data, null, doShow, props );

};



export default Map;