import { act } from "@testing-library/react";
import { useEffect, useRef, useState } from "react"
import { createUseStyles } from "react-jss";
import { useQuery } from "@apollo/client"
import iconChevronLeft from "../img/icon_chevron_left.svg";
import iconChevronRight from "../img/icon_chevron_right.svg";
import ThemeData from "data/ThemeData";
const { default: QueryData } = require("data/QueryData")


export const UnitDetailCarousel = ({unit, units, setSelectedUnitId, setGlobalUnit }) => {
    const { loading } = useQuery(QueryData.unitProperties);
    const [current, setCurrent] = useState(units);
    const elementRef = useRef();
    const contentRef = useRef();
    const [cssWidths, setWidths] = useState({
        elementWidth: elementRef.current && elementRef.current.offsetWidth,
        parentWidth: contentRef.current && contentRef.current.offsetWidth
    })

    const index = units.indexOf(units.filter(el => el.custom_id === unit.custom_id)[0]);
    const [activeIndex, setActiveIndex] = useState(index);

    const useStyles = createUseStyles({
        carouselWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            overflow: 'hidden',
        },
        carouselContentWrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            overflow: 'hidden',
            justifyContent: 'center',
        },
        buttonContainer: {
            width: '20px',
            position: 'relative'
        },
        buttonLeft:{
            right: '2px',
            top: '25px',
            position: 'absolute',
            margin: 'auto 0',
            border: `solid ${ThemeData.colours.primaryAccent}`,
            borderWidth: '0 3px 3px 0',
            padding: '3px',
            display: 'inline-block',
            transform: 'rotate(135deg)',
            zIndex: 2,
            cursor: 'pointer'
        },
        buttonRight:{
            top: '25px',
            right: '2px',
            position: 'absolute',
            margin: 'auto 0',
            border: `solid ${ThemeData.colours.primaryAccent}`,
            borderWidth: '0 3px 3px 0',
            padding: '3px',
            display: 'inline-block',
            transform: 'rotate(-45deg)',
            zIndex: 2,
            cursor: 'pointer'
        },
        planText: {
            color: ThemeData.colours.white,
            fontWeight: '700',
            fontSize: '15px',
            lineHeight: '47px',
            whiteSpace: 'nowrap',
        },
        bedsText: {
            fontWeight: '700',
            fontSize: '10px',
            lineHeight: '15px'
        },
        textContainer: {
            transform: (props) => `translateX(${((props.activeIndex-(props.current.length/2))*(-186))-90}px)`,
            padding: '0 18px',
            minWidth: 300,
            transition: 'all 0.5s ease-in-out',
            cursor: 'pointer',
            color: ThemeData.colours.aaeForestGreen
        },
        textContainerWithOpacity: {
            transform: (props) => `translateX(${((props.activeIndex-(props.current.length/2))*(-186))-90}px)`,
            padding: '0 18px',
            opacity: '25%',
            minWidth: '150px',
            transition: 'all 0.5s ease-in-out',
            cursor: 'pointer',
            color: ThemeData.colours.aaeForestGreen25
        }
    })
    
    const classes = useStyles({activeIndex, current, cssWidths});

    const handleClick = (dir) => {
        setActiveIndex(activeIndex+dir);
        setGlobalUnit(units[activeIndex+dir].custom_id)
        setSelectedUnitId(units[activeIndex+dir].custom_id)
    }

    useEffect(() => {
        setWidths({
            elementWidth: elementRef.current.offsetWidth,
            parentWidth: contentRef.current.offsetWidth
        })
        setCurrent(units);
    },[activeIndex, unit, units])
    
    const renderUnitsDetails = () => {
        return current.map((unitElement, id) => 
            <div onClick={() => handleClick(id - activeIndex)} ref={elementRef} className={id !== activeIndex ? classes.textContainerWithOpacity : classes.textContainer}>
                <div className={classes.planText}>{unitElement.unit_plan_type.name}</div>
                {/* <div className={classes.bedsText}>{unitElement.unit_home_type.name}</div> */}
          </div>
        )
    }

    return (
        <div className={classes.carouselWrapper}>
            {activeIndex !== 0 && <div className={classes.buttonContainer}><div className={classes.buttonLeft} onClick={() => handleClick(-1)} /></div> }                        
                    <div className={classes.carouselContentWrapper} ref={contentRef}
                    >
                        {loading ? <></> : renderUnitsDetails()}             
                    </div>
            <div className={classes.buttonContainer}>
                {activeIndex !== units.length-1 &&  <div className={classes.buttonRight} onClick={() => handleClick(1)} />}
            </div>
        </div>
    )


}
