import React, {
	useEffect,
	useState,
	useMemo,
} from "react";

import { createUseStyles } from "react-jss";

import {
	useHistory,
} from "react-router-dom";

import {
	motion,
} from 'framer-motion';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import SwiperCore, {
	Controller,
	Pagination,
	Navigation,
	Mousewheel,
} from "swiper";

import ConstantData from "../data/ConstantData";
import ThemeData from "../data/ThemeData";

import imgFunctions from "util/imgFunctions";

import Map from "components/Map";
import ContentPageNeighbourhoodPois from "./ContentPageNeighbourhoodPois";
import HeaderComponents from "./HeaderComponents";
import HeaderContextData from "data/HeaderContextData";
import VirtualTour from "./VirtualTour";
import SavedHomesSlideout from "./SavedHomesSlideout";

import contentPageX from "../img/content_page_x.svg";
import contentPageXDark from "../img/content_page_x_dark.svg";
import { ContentNavigationCarousel } from "./ContentNavigationCarousel";

SwiperCore.use( [Pagination, Mousewheel] );

const useStyles = createUseStyles(
	{
		wrapper: {
			height: '100%',
			width: '100%',
			color: ThemeData.colours.black,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 999
		},
		contentPage: {
			display: 'flex',
			flexDirection: 'row',

			height: 'calc( 100vh - var( --header-height ) )',
		},
		backButtonWrapper: {
			display: 'flex',
			alignItems: 'center',

		},
		backButton: {
			flex: '0 0 auto',
			cursor: 'pointer',
			paddingLeft: 40,

			color: ThemeData.colours.primaryFont,
			fontSize: 24,
		},
		contentPane: {
			width: 390,
			height: 'calc( 100vh - var( --header-height ) )',
            cursor: 'pointer',
			flex: '0 0 auto',
            zIndex: 10,
			display: 'flex',
			flexDirection: 'column',

			//backgroundColor: ThemeData.colours.secondaryBg,
			backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(https://storage.googleapis.com/pxa-arcane-prod/arcane_left_panel_1e8af9f8c7/arcane_left_panel_1e8af9f8c7.png)`,
			backgroundSize: 'cover',

			textAlign: 'left',

			'&.withMap': {
				width: 390,
                '&.contentContainer': {
                    height: '75%'
                }
			},
		},
		contentContainer: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
            height: '75%',
			padding: 40,
		},
		navContainer: {
			height: 150,

			flex: '0 1 auto',

			padding: '0 40px 30px 40px',
		},
		navArrow: {
			marginTop: 30,
		},
		navArrowLeft: {

		},
		navArrowRight: {
			marginLeft: 20,
		},
		navArrowDisabled: {
			opacity: .1,
		},
		rightPane: {
			flex: '1 1 auto',
			width: '100%',
			height: '100%',

			background: 'no-repeat center',
			backgroundSize: 'cover',
			objectFit: 'contain',
		},
		swiperSlideImg: {
			width: 10,
		},
		rightPaneVideo: {
			display: 'flex',
			objectFit: 'contain',
		},
		rightPaneFullsized: {
			backgroundSize: 'contain',
		},
		swiper: {
			'--swiper-theme-color': ThemeData.colours.primaryAccent,
		},
		contentArea: {
			flex: '0 0 auto',
		},
		sectionName: {
			flex: '0 0 auto',

			color: ThemeData.colours.primaryAccent,

			fontFamily: ThemeData.fonts.title,
			fontSize: 16,
		},
		title: {
			flex: '0 0 auto',

			color: ThemeData.colours.secondaryTitleFont,

			marginTop: props => props.doRenderInteractiveMap ? 0 : '.5em',
			marginBottom: props => props.doRenderInteractiveMap && 0,

			fontFamily: ThemeData.fonts.title,
			fontSize: props => props.doRenderInteractiveMap ? '1.5em' : '2.5em',
			letterSpacing: 0,
			lineHeight: '1.2em',
		},
		content: {
			flex: '1 1 auto',
			color: ThemeData.colours.secondaryFont,

			fontFamily: ThemeData.fonts.copy,
			fontSize: '1.2em',
			letterSpacing: '0.0125em',
			lineHeight: '2em',

			overflowY: 'auto',

			...ThemeData.styles.thinScrollbar,
		},
		contentPageX: {
			position: 'absolute',
			right: 0,

			width: 150,
			height: 150,

			background: 'no-repeat center',
			backgroundImage: `url('${contentPageX}')`,

			filter: 'drop-shadow(2px 2px 2px rgb(0, 0, 0, .15))',

			"&.isDark": {
				backgroundImage: `url('${contentPageXDark}')`,
			},
		},
		mapPane: {
			display: 'none',
            position: 'relative',
			'&.doShow': {
				display: 'block',
			},
		},
	},
	{
		name: "ContentPage",
	}
);
function ContentPageCarousel( { sectionData, tab, setTab, ...props } ) {
	let contentPage = tab !== -1 ? sectionData.filter(data => data.order === tab)[0] : sectionData[0];
	let doRenderInteractiveMap = false;
	let doOnlyRenderPhotos = false;
	doRenderInteractiveMap = contentPage.custom_function === ConstantData.contentPageCustomFunctions.interactiveMap;
	doOnlyRenderPhotos = contentPage.custom_function === ConstantData.contentPageCustomFunctions.photosOnly;
	const classes = useStyles({tab, contentPage, doRenderInteractiveMap});
    const history = useHistory();

	const unitFcns = {
		selectedUnitId: props.selectedUnitId,
		setSelectedUnitId: props.setSelectedUnitId,

		isUnitDetailOpen: props.isUnitDetailOpen,
		setIsUnitDetailOpen: props.setIsUnitDetailOpen,
		toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
	}

	const [distance, toggleDistance] = useState([]);

	const [highlightCatPoi, setHighlightCatPoi] = useState( {
		isHighActive: false,
		highCatId: -1,
		highPoiId: -1,
	} );

	

	// useEffect( () => {
	// }, [highlightCatPoi] );

	const [procFlyTo, setProcFlyTo] = useState( {
		doProcFlyTo: false,
		poiid: -1,
		catid: -1,
		coordinates: -1,
	} );

	const customFunctionContent = useMemo( () => {
		return {
			[ConstantData.contentPageCustomFunctions.virtualTour]: (
				<VirtualTour
					isOpen={true}
					src={sectionData ? sectionData.length > 0 && sectionData[0].custom_iframe_embed_url : ''}
					toggleVirtualTourFcn={() => history.goBack()}
				/>
			)
		};
	}, [ConstantData, sectionData, unitFcns] )

	const textMediaPairs = useMemo( () => {
		if ( sectionData ) {
			if ( contentPage.text_media_pairs && contentPage.text_media_pairs.length > 0 ) {
				let tmpAll = [
					{
						text: contentPage.content,
						media: contentPage.image && contentPage.image.length > 0 && contentPage.image[0],
					}
				];

				for ( let tmp of contentPage.text_media_pairs ) {
					tmpAll.push( {
						text: tmp.text,
						media: tmp.media,
					} );
				}

				return tmpAll;
			}
		}

		return null;
	}, [contentPage.content, contentPage.image, contentPage.text_media_pairs, sectionData] )

	const doOnlyRenderSingleTextContent = useMemo( () => {
		if ( textMediaPairs && textMediaPairs.length > 0 ) {
			let areAllTMPTextsBlank = true;
			if ( textMediaPairs.length > 1 ) {
				for ( let i = 1; i < textMediaPairs.length; i++ ) {
					if ( textMediaPairs[i].text )
						areAllTMPTextsBlank = false;
				}
			}
			return areAllTMPTextsBlank;
		}
		return false;
	}, [textMediaPairs] )

	
	const [textSwiper, setTextSwiper] = useState( null );
	const [mediaSwiper, setMediaSwiper] = useState( null );
	

	const renderContentPageMediaPane =  contentPageMedia => {
		return (
				<div
					className={`${classes.rightPane} ${doOnlyRenderPhotos ? classes.rightPaneFullsized : ''}`}
					style={contentPageMedia && { backgroundImage: `url('${imgFunctions.GetImgBySizeUri( contentPageMedia, ConstantData.imgSizes.xlarge )}')` }}
				/>
		)
	} 
	return contentPage.order === tab && (
		<>
          <motion.div
			className={classes.wrapper} 
            id={contentPage.id}
			initial={{ y: '100vh' }}
			animate={{ y: 0 }}
			exit={{ y: '100vh' }}
			transition={{ type: 'tween', duration: .5 }}
		>
			{props.doRenderHeader &&
				<HeaderComponents 
                    headerContext={HeaderContextData.contentPageHeaderContext} 
                    setIsUnitDetailOpen={props.setIsUnitDetailOpen}
					toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen}
					toggleIsRegisterOpen={props.toggleIsRegisterOpen}
                    backgroundColor={ThemeData.colours.primaryHeaderBg}
                >
					<div className={classes.backButtonWrapper}>
                        <div
                            className={classes.backButton}
                            onClick={() => setTab(-1)}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> BACK
                        </div>
					</div>
				</HeaderComponents>
			}
			<div
				className={classes.contentPage}
                id={`content-page-${contentPage.id}`}
			>
				{contentPage.custom_function &&
					!( doRenderInteractiveMap || doOnlyRenderPhotos )
					?
					customFunctionContent[contentPage.custom_function]
					:
					<>
						{!doOnlyRenderPhotos &&
							<div className={`${classes.contentPane} ${doRenderInteractiveMap ? "withMap" : ""}`}>
								<div className={classes.contentContainer}>
									<h4 className={`${classes.contentArea} ${classes.sectionName}`}>
										{contentPage.section.slug.toUpperCase()}
									</h4>
									<h2 className={`${classes.contentArea} ${classes.title}`}>
										{contentPage.title.toUpperCase()}
									</h2>
							
										{doRenderInteractiveMap ?
											<ContentPageNeighbourhoodPois
												distance={distance}
												toggleDistance={toggleDistance}
												highlightCatPoi={highlightCatPoi}
												setHighlightCatPoi={setHighlightCatPoi}
												setProcFlyTo={setProcFlyTo}
											/>
											:
											!doOnlyRenderSingleTextContent && textMediaPairs && textMediaPairs.length > 0 ?
												<Swiper
													className={classes.rightPaneSwiper}
													modules={[Controller]}
													onSwiper={setTextSwiper}
													controller={{ control: mediaSwiper }}
													allowTouchMove={false}
													
												>
													{textMediaPairs
														.map( ( tmp, idx ) => (
															<SwiperSlide key={idx}>
																<ReactMarkdown rehypePlugins={[rehypeRaw]}>{tmp.text}</ReactMarkdown>
															</SwiperSlide>
														) )}
												</Swiper>
												:
												<ReactMarkdown className={classes.content} rehypePlugins={[rehypeRaw]}>{contentPage.content}</ReactMarkdown>
										}
								</div>
								{tab !== -1 && <ContentNavigationCarousel
                                    tab={tab}
                                    setTab ={setTab}
									tabs={sectionData} 
									currentTab={contentPage}
									/>}
							</div>}
						<div className={`${classes.rightPane} ${classes.mapPane} ${doRenderInteractiveMap ? "doShow" : ""}`} >
							{doRenderInteractiveMap && <Map
								doShow={doRenderInteractiveMap}
								highlightCatPoi={highlightCatPoi}
								setHighlightCatPoi={setHighlightCatPoi}
								procFlyTo={procFlyTo}
							/>}
						</div>
						{doRenderInteractiveMap ?
							""
							:
							textMediaPairs && textMediaPairs.length > 0 ?
								<>
									<Swiper
										className={classes.swiper}
										modules={[Controller, Navigation]}
										onSwiper={setMediaSwiper}
										controller={{ control: textSwiper }}
										slidesPerView = {1}
										navigation = {{ clickable: true }}
										mousewheel={true}
									>
										
										{textMediaPairs
											.map( ( tmp, idx ) => (
												<SwiperSlide
													className={classes.swiperSlideImg}
													key={idx}
												>
													{renderContentPageMediaPane( tmp.media )}
												</SwiperSlide>
											) )}
									</Swiper>
								</>
								:
								renderContentPageMediaPane( contentPage.image && contentPage.image[0] )
						}
					</>
				}
			</div>
		</motion.div>
		<SavedHomesSlideout
			isOpen={props.isSavedHomesSlideoutOpen}
			toggleIsOpen={() => props.toggleIsSavedHomesSlideoutOpen()}
			toggleIsSavedHomesCompareOpen={() => props.toggleIsSavedHomesCompareOpen()}
			toggleIsRegisterOpen={props.toggleIsSendToEmailOpen}
		/>
	</>
	);
}



ContentPageCarousel.defaultProps = {
	doRenderHeader: true,
}



export default ContentPageCarousel;