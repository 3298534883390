import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";


import useSavedHomes from "hooks/useSavedHomes";



const useStyles = createUseStyles(
	{
		wrapper: {
			padding: 20,

			textAlign: 'left',
		},
		savedHomes: {
			flex: '0 0 auto',

			// width: 175,

			// marginRight: 50,

			// cursor: 'pointer',

			...ThemeData.typography.nameplateNoColor,
		},
		savedHomesNumberText: props => ( {
			display: 'inline',

			margin: '0 5px',
			padding: '3px 4px',

			color: props.color,
			backgroundColor: props.numberBgColor,

			borderRadius: 10,

			'.mainMenuHeader &': {
				backgroundColor: ThemeData.colours.secondaryAccent,
			},
		} ),
	},
	{
		name: "SavedHomesTitle",
	}
)



const SavedHomesTitle = ( { data, ...props } ) => {

	const classes = useStyles( props );

	const {
		savedHomes,
		// toggleSavedHome,
	} = useSavedHomes();


	return (
		<div
			className={`${classes.savedHomes} ${props.className}`}
			onClick={props.onClick}
		>
			<p id="saved-homes">{props.titleText} &nbsp;<span className={classes.savedHomesNumberText}>&nbsp;&nbsp;{savedHomes.length}&nbsp;&nbsp;</span></p>
		</div>
	);
};



SavedHomesTitle.defaultProps = {
	titleText: "Saved Homes",
	color: ThemeData.colours.white,
	numberBgColor: ThemeData.colours.secondaryAccent,
};



export default SavedHomesTitle;